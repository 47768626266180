import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";
import { blogList } from "../../data/blogs";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/best-places-to-live-in-san-diego");

  return (
    <LayoutWrapper
      title="Best Places to Live in San Diego"
      desc="Whether it’s the nightlife or family-friendly amenities, you’ll find it all in San Diego. Read on to learn more about the best places to live in San Diego."
      headerLogo="../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              Best Places to Live in San Diego
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>

                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  March 24, 2023
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Best Places to Live in San Diego.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Check Out Some of the Best Places to Live in San Diego!
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Is there a neighborhood in San Diego that has been calling you? Perhaps you recently
                moved to the area and want to buy a home in a place with like-minded neighbors and
                plenty to do.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Whether you're a young professional looking for weekend nightlife or a young family
                looking for family-friendly amenities, you'll find what you're looking for in sunny
                Southern California. Here are some of the best places to live in San Diego.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Top 5 Ranking Neighborhoods in San Diego{" "}
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Want to find where the best neighborhoods in San Diego are? Here are the top five
                ranking neighborhoods to consider in San Diego:
              </p>

              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                1. Bankers Hill{" "}
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                One of San Diego's most established neighborhoods, Bankers Hills borders downtown
                Mission Hills, Little Italy, and Balboa Park. It is best known for its panoramic
                city views, central location, and high-rise condos. However, this luxurious San
                Diego neighborhood is also known for its affluent residents, with an average cost of
                rent of around $2,400.{" "}
              </p>

              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                2. Hillcrest{" "}
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                San Diego is known for its diversity and inclusion and has one of the most
                LGBTQIA-friendly neighborhoods in the city, Hillcrest. With trendy stores, an
                exhilarating nightlife, and much gender diversity, Hillcrest is an exciting hub
                where something always seems to happen. In addition, Hillcrest is home to San
                Diego's largest farmer's market and has restaurants, cafes, and retail shops, all
                within walking distance.
              </p>

              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                3. North Park{" "}
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you're looking for a trendy San Diego neighborhood ideal for young working
                professionals, consider North Park. It is known for being one of the most "walkable
                neighborhoods in San Diego" and stretches across the north side of Balboa Park.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As one of the city's oldest neighborhoods, most of the homes built in the area date
                back to the 1920s (with much newer upgrades, of course). Today it is known for its
                "hipster" population and has plenty of nightlife for those interested in the club
                scene.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                4. South Park{" "}
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                One of San Diego's favorite urban neighborhoods, South Park, has a vast selection of
                food, coffee shops, and retail stores for you to enjoy. You can also enjoy craft
                beers and cocktails at the local breweries, and luxury retail shops.{" "}
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                5. University Heights{" "}
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                This city area was established in 1888 and is a fairly walkable neighborhood.
                Although University Heights gets its name from its location on University Avenue, it
                was also the main thoroughfare before the interstate system was built. It is a
                highly prestigious area known as one of the safest in the city.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Find Your Dream Home with the Selby Team
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                No matter where in San Diego you want to buy a home, the kind professionals at the
                Selby Team can assist you with finding the perfect home to meet your family's needs.
                Don't spend hours "shopping" online. We have many properties available in the best
                communities and can help you find your perfect home.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <ContactSlideoutLink text="Contact The Selby Team" /> to begin the search for your
                new home today!
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
